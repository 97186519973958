import React from 'react';
import 'react-phone-number-input/style.css';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

import './CountrySelect.scss';

const CustomCountrySelect = ({ value, onChange, ...rest }) => {
  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    onChange(selectedCountryCode);
  };

  const countryOptions = getCountries().map((countryName) => {
    const countryCodes = getCountryCallingCode(countryName);
    const formattedCountryCodes = Array.isArray(countryCodes)
      ? countryCodes.map((code) => code.toUpperCase())
      : [countryCodes.toUpperCase()];

    return formattedCountryCodes.map((countryCode) => (
      <option key={countryCode} value={countryName}>
        {countryName} ({countryCode})
      </option>
    ));
  });

  return (
    <div className="country-select-container">
      <select
        className="custom-select"
        {...rest}
        value={value}
        onChange={handleCountryChange}
      >
        {countryOptions}
      </select>
    </div>
  );
};

export default CustomCountrySelect;
