import React from 'react';
import { Select } from 'antd';
import styles from './customSelectDropdown.module.scss';

const { Option } = Select;

const CustomSelectDropdown = ({
  label,
  value,
  onChange,
  name,
  showSearch,
  mode,
  data = [],
}) => {
  return (
    <div className={styles['ant-select-selector']}>
      <label className={styles['custom-select-label']}>{label}</label>
      <Select
        suffixIcon={null}
        aria-hidden="true"
        mode={mode}
        defaultActiveFirstOption={true}
        showSearch={showSearch}
        style={{ width: '100%' }}
        value={value}
        name={name}
        onChange={onChange}
      >
        {data.map((data, index) => (
          <Option key={data?.id || index} value={data.value}>
            {data.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CustomSelectDropdown;
