import React from 'react';

import goalIcon from '../../../assets/logo/dart.svg';
import checkList from '../../../assets/logo/checklist.svg';
import styles from './detailsGoal.module.scss';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const DetailsGoal = ({ longTermGoals, shortTermGoals, carePlanHistory }) => {
  const navigate = useNavigate();
  const completedCarePlans = carePlanHistory
    .filter((cp) => cp.status === 'COMPLETED')
    .sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
    );

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.header}>
          <img src={goalIcon} alt="" />
          <h1>Goals</h1>
        </div>
        <div className={styles.leftContent1}>
          <h2>SHORT TERM GOALS</h2>
          <span>{shortTermGoals}</span>
        </div>
        <div className={styles.leftContent2}>
          <h2>LONG TERM GOALS</h2>
          <span>{longTermGoals}</span>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.header}>
          <img src={checkList} alt="" />
          <h1>Patient Care Plan History</h1>
        </div>
        <div className={styles.rightHeader}>
          <h3>DATE</h3>
          <h3>PRACTITIONER</h3>
        </div>
        {completedCarePlans.map((plan) => (
          <div key={plan._id || plan.id} className={styles.history}>
            <h4>{moment(plan.createdAt).format('LL')}</h4>
            <h4>{`${plan.practitioner?.firstName} ${plan.practitioner?.lastName}`}</h4>
            <button
              onClick={() =>
                navigate(`/dashboard/careplan-summary/${plan._id || plan.id}`)
              }
            >
              View
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailsGoal;
