import React from 'react';
import './Account.scss';
import { useState } from 'react';
import Navbar from '../Navbar';
import ProfileDisplayCard from './profileDisplayCard';
import SecurityDisplayCard from './securityDisplayCard'; // Replace with the actual import
import PaymentDisplayCard from './paymentDisplayCard'; // Replace with the actual import
import LocationDisplayCard from './locationDisplayCard'; // Replace with the actual import
// import CalendarDisplayCard from './calendarDisplayCard'; // Replace with the actual import
import Sidebar from './sidebar';
import { useSelector } from 'react-redux';

const Account = () => {
  const [showContent, setShowContent] = useState(0);
  const { currentTab } = useSelector((state) => state.account) || {};

  return (
    <>
      <Navbar />
      <div className="account_body">
        <h1 className="header">Account</h1>
        <div className=" wrapper container">
          <div className="sidebar_wrapper">
            <Sidebar onButtonClick={(index) => setShowContent(index)} />
          </div>
          <div className="content_wrapper">
            {(() => {
              switch (currentTab) {
                case 'profile':
                  return <ProfileDisplayCard />;
                case 'security':
                  return <SecurityDisplayCard />;
                case 'payment':
                  return <PaymentDisplayCard />;
                case 'location':
                  return <LocationDisplayCard />;
                // case 'calendar':
                //   return <CalendarDisplayCard />;
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
