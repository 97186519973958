import Button from 'components/Button';
import CustomSelectDropdown from '../../../CustomSelectDropdown/CustomSelectDropdown';
import styles from './shortTermGoal.module.scss';
import { useDispatch } from 'react-redux';
import { setShortTermGoals } from 'redux/slices/currentCarePlan';
import { periodData, reductionLevelData } from 'utils/constants';

const ShortTermGoal = ({
  period,
  reductionLevel,
  setPeriod,
  setReductionLevel,
  closeModal,
  assessment,
}) => {
  const dispatch = useDispatch();

  console.log(
    {
      period,
      reductionLevel,
    },
    'this is the short term goal'
  );

  const onClose = () => {
    dispatch(
      setShortTermGoals({
        assessment: {
          ...assessment,
          shortTermGoal: { period, reductionLevel },
        },
      })
    );
    closeModal();
  };
  return (
    <div className={styles.root}>
      <h1>Add short term goal</h1>
      <span>Set desired level of improvement and period of time</span>
      <div className={styles.options}>
        <CustomSelectDropdown
          value={reductionLevel}
          label="Reduce level of depression & anxiety by"
          height="50px"
          data={reductionLevelData}
          onChange={(val) => setReductionLevel(val)}
        />
        <CustomSelectDropdown
          value={period}
          label="After a period of "
          height="50px"
          data={periodData}
          onChange={(val) => setPeriod(val)}
        />
      </div>
      <Button label={'Set short term goal'} type={'button'} onClick={onClose} />
    </div>
  );
};

export default ShortTermGoal;
