import React, { useEffect, useState } from 'react';
import styles from './billingTable.module.scss';

import Modal from '../../../components/Modal/Modal';
import BillingModal from './billingModal/BillingModal';
import moment from 'moment';
import { Spin } from 'antd';

import useDebounce from '../useDebounce';
import { api, fetcher } from 'api';

const BillingTable = ({ searchInput }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [currentLocationId, setCurrentLocationId] = useState(null);
  const [currentClaimId, setCurrentClaimId] = useState(null);

  const debouncedSearchInput = useDebounce(searchInput, 1000);

  const itemsPerPage = 15;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`claims/history`, {
          params: {
            limit: itemsPerPage,
            page: currentPage,
            billingStatus: 'successful',
            patientName: debouncedSearchInput,
          },
        });

        if (response.data && response.data?.data?.history) {
          console.log('object:', response.data && response.data?.data?.history);
          setDisplayedItems(response?.data?.data?.history);
          setTotalPages(response?.data?.data?.totalPages);
        } else {
          console.error('Invalid response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, debouncedSearchInput]);

  const displayedItemsForCurrentPage = displayedItems;

  const displayPageNumbers = () => {
    const pageNumbers = [currentPage];

    for (let i = 1; i <= 2 && currentPage + i <= totalPages; i++) {
      pageNumbers.push(currentPage + i);
    }

    for (let i = 1; i <= 2 && currentPage - i > 0; i++) {
      pageNumbers.unshift(currentPage - i);
    }

    return pageNumbers;
  };

  const handleSeeDetailsClick = async (locationId, claimId) => {
    try {
      setLoadingDetails(true);
      const response = await api.post(
        `claims/check-claim?locationId=${locationId}&claimId=${claimId}`
      );

      if (response.data) {
        setSelectedRowData({
          ...selectedRowData,
          billingDetails: response.data,
        });
        setCurrentLocationId(locationId);
        setCurrentClaimId(claimId);
        setShowBillingModal(true);
      } else {
        console.error('Invalid response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingDetails(false);
    }
  };

  return (
    <div>
      {loading || loadingDetails ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.95)',
            zIndex: 1000,
          }}
        >
          <Spin tip="Loading..." className={styles['ant-spin-dot-item']} />
        </div>
      ) : null}

      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr className={styles.tableHeader}>
              <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>
                PATIENT
              </th>
              <th style={{ fontFamily: 'Avenir' }}>DATE OF BILLING</th>
              <th style={{ fontFamily: 'Avenir' }}>BILLING STATUS</th>
              <th style={{ fontFamily: 'Avenir' }}>AMOUNT</th>
              <th style={{ fontFamily: 'Avenir' }}>ITEM NUMBER</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {displayedItemsForCurrentPage.map((row, index) => (
              <>
                <tr className={styles.tableRow} key={index}>
                  <td>
                    <div className="custom-table-image">
                      <p>{row.patient}</p>
                    </div>
                  </td>
                  <td>{moment(row.timeReceived).format('MMMM Do YYYY')}</td>
                  <td
                    className={
                      row.status === 'WITH MEDICARE'
                        ? styles.success
                        : styles.failed
                    }
                  >
                    {row.status}
                  </td>
                  <td>
                    {' '}
                    $
                    {row.items && row.items.length > 0
                      ? row.items[0]?.amount
                      : 'N/A'}
                  </td>
                  <td>
                    {' '}
                    {row.items && row.items.length > 0
                      ? row.items[0]?.itemNumber
                      : 'N/A'}{' '}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        handleSeeDetailsClick(row.locationId, row.claimId);
                      }}
                      className="table-details-button"
                    >
                      See Details
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div className="paginationContainer">
        <span
          className={`page-number ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &lt; Prev
        </span>

        {displayPageNumbers().map((pageNumber) => (
          <span
            key={pageNumber}
            className={`page-number ${
              pageNumber === currentPage ? 'active' : ''
            }`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </span>
        ))}

        <span
          className={`page-number ${
            currentPage === totalPages ? 'disabled' : ''
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next &gt;
        </span>
      </div>
      <Modal
        isOpen={showBillingModal}
        onClose={() => {
          setShowBillingModal(false);
          setSelectedRowData({ ...selectedRowData, billingDetails: null });
        }}
        children={
          <BillingModal
            billingDetails={selectedRowData?.billingDetails}
            locationId={currentLocationId}
            claimId={currentClaimId}
            billtype={selectedRowData?.billingDetails?.data?.type}
          />
        }
      />
    </div>
  );
};

export default BillingTable;
