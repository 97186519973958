import Button from './Button';
import Input from './FormInput';
import './LoginForm.scss';
import '../global-styles.scss';
import { Link, Router } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { login } from '../redux/actions/authAction';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = useState(null);
  const validationSchema = Yup.object().shape({
    // Define your validation rules for each input field
    email: Yup.string().required(),
    password: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
      try {
        setLoading(true);
        await dispatch(login(values));
        setLoading(false);
        setErrorMessage(null);
        navigate('/dashboard');
      } catch (error) {
        setLoading(false);
        const errorMessage =
          error?.response?.data?.message ||
          'There was an error. Please try again later';
        setErrorMessage(errorMessage);
      }
    },
  });

  return (
    <>
      <form className="login-form-container" onSubmit={formik.handleSubmit}>
        <div className="login-form-inner-conatiner">
          <div className="login-form-header">
            <h3>Welcome back</h3>
            <p>Sign into your account.</p>
          </div>
          {error && (
            <div className="form-action-message">
              <p>{error}</p>
            </div>
          )}

          <div className="login-form-input-container">
            <div className="input-column">
              <Input
                label="Email Address"
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={!!formik.errors.email}
              />
            </div>
            <div className="input-column">
              <Input
                label="Password"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={!!formik.errors.password}
              />
            </div>
            <small>
              {' '}
              <Link className="nav-link">Forgot Password?</Link>
            </small>

            <div className="login-form-button-container">
              <Button
                label="Sign In"
                type="submit"
                disabled={!formik.isValid}
                isLoading={loading}
              />
            </div>
            <div className="have-account">
              <small>Don't have an Account?</small>
              <Link className="nav-link" to="/sign-up">
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
