import SignUpPage from './pages/SignUpPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AccountVerification from './pages/AccountVerification';
import LoginPage from './pages/LoginPage';
import PrivacyPage from 'pages/privacyPage';
import Dashboard from './pages/Dashboard';
import DashboardUserDetails from './pages/DashboardUserDetails/DashboardUserDetails';
import { PatientNote } from './pages/PatientNote/PatientNote';
import DashboardLayout from './layouts/DashboardLayout';
import Billings from './pages/Billing';
import CreateCarePlan from './pages/CreateCarePlan/CreateCarePlan';
import KycPending from './pages/VerificationPending';
import Account from './components/profileComponents/Account';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BillingProvider } from 'components/CreateCarePlan/CreateCareBilling/amount/billingContext';
import BillingSummary from 'components/CreateCarePlan/BillingSummary/BillingSummary';
import TermsPage from 'pages/TermsPage';

const App = () => {
  return (
    <BillingProvider>
      <>
        <Router>
          <ToastContainer />
          <Routes>
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/verify-account" element={<AccountVerification />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
              <Route
                path="/dashboard/user-details/:id"
                element={<DashboardUserDetails />}
              />
              <Route
                path="/dashboard/user-patient-note/:id"
                element={<PatientNote />}
              />
              <Route path="/dashboard/billing" element={<Billings />} />
              <Route
                path="/dashboard/careplan-summary/:id"
                element={<BillingSummary />}
              />
            </Route>
            <Route path="/create-care-plan/*" element={<CreateCarePlan />} />
            <Route path="/kyc-pending" element={<KycPending />} />
            <Route path="/account" element={<Account />} />
          </Routes>
        </Router>
      </>
    </BillingProvider>
  );
};

export default App;
