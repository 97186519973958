import './TermsAndConditions.scss';
import { Link } from 'react-router-dom';
const TermsAndConditions = () => {
  return (
    <div className="terms-conditions-container">
      <small>
        <Link to="/terms" className="privacy-policy-link ">
          Terms and conditions
        </Link>
      </small>
      <small>
        <Link to="/privacy" className="privacy-policy-link ">
          Privacy Policy
        </Link>
      </small>
    </div>
  );
};

export default TermsAndConditions;
