import React, { useState } from 'react';
import Avatar from '../../assets/logo/userAvatar.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import './dashboardProfile.scss';
import Modal from '../Modal/Modal';
import InvitePatient from './InvitePatient/InvitePatient';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getGreeting } from '../../utils/greeting';

const DashboardProfile = ({ isKycApproved = true }) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <>
      <div className="dashboard-profile">
        <div className="dashboard-profile-image">
          <img src={Avatar} alt="" />
          <div>
            <p>
              {getGreeting()}, Dr {user.lastName} 👋
            </p>
            <h1>Your Dashboard</h1>
          </div>
        </div>
        <div className="dashboard-profile-action">
          {isKycApproved ? (
            <div className="dashboard-profile-action-invite-allowed">
              <button
                onClick={() => setOpenInviteModal(true)}
                className="dashboard-profile-invite"
              >
                <span>Invite Patient</span>
              </button>
            </div>
          ) : (
            <div className="dashboard-profile-action-invite-disabled">
              <img src={PlusIcon} alt="" />
              <span>Invite Patient</span>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
        children={<InvitePatient closeModal={setOpenInviteModal} />}
      />
    </>
  );
};

export default DashboardProfile;
