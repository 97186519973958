import moment from 'moment';
import React from 'react';

const CurrentPatientsTable = ({ data, navigate, defaultProfilePicture }) => {
  return (
    <table>
      <thead>
        <tr className="table-head">
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>Patient</th>
          <th style={{ fontFamily: 'Avenir' }}>Age</th>
          <th style={{ fontFamily: 'Avenir' }}>Location</th>
          <th style={{ fontFamily: 'Avenir' }}>Recent K10 Score</th>
          <th style={{ fontFamily: 'Avenir' }}>Most Recent Visit</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ user, assessmentDate, recentScore }) => (
          <tr className="table-row" key={user?._id}>
            <td>
              <div className="custom-table-image">
                <img
                  src={defaultProfilePicture}
                  alt={user?.firstName}
                  className=""
                />
                <p>{`${user?.firstName} ${user?.lastName}`}</p>
              </div>
            </td>
            <td>{moment().diff(user?.dateOfBirth, 'years')}</td>
            <td>{user?.city}</td>
            <td>{recentScore}</td>
            <td>{moment(assessmentDate).format('LL')}</td>
            <td>
              <div>
                <button
                  onClick={() =>
                    navigate(`/dashboard/user-details/${user?._id}`)
                  }
                  className="table-details-button"
                >
                  See Details
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CurrentPatientsTable;
