import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleNextPlanStep } from '../../../redux/slices/carePlanSlice';
import { useNavigate } from 'react-router-dom';
import styles from './assessment.module.scss';
import GoBackButton from '../../GoBackButton/GoBackButton';
import avatar from '../../../assets/logo/userAvatar.svg';
import CustomSelectDropdown from '../../CustomSelectDropdown/CustomSelectDropdown';
import Button from 'components/Button';
import useSWR from 'swr';
import { fetcher } from 'api';
import { createCarePlan, updateCarePlan } from 'redux/actions/carePlanAction';
import { toast } from 'react-toastify';
import { setCurrentCarePlan } from 'redux/slices/currentCarePlan';

const riskOptions = [
  { value: 'Low risk', label: 'Low Risk' },
  { value: 'Medium risk', label: 'Medium Risk' },
  { label: 'High Risk', value: 'High risk' },
];

const Assessment = () => {
  const { assessment, assessmentOverview, user } =
    useSelector((state) => state.carePlanSummary) || {};

  const {
    diagnosis: diagnosisFromStore,
    riskToOthers: riskToOthersFromStore,
    riskToSelf: riskToSelfFromStore,
    _id,
  } = useSelector((state) => state.currentCarePlan) || {};
  const currentCarePlan = useSelector((state) => state.currentCarePlan);
  const [riskToSelf, setRiskToSelf] = React.useState(
    riskToSelfFromStore || riskOptions[0]?.value
  );
  const [riskToOthers, setRiskToOthers] = React.useState(
    riskToOthersFromStore || riskOptions[0]?.value
  );
  const [diagnosis, setDiagnosis] = React.useState(diagnosisFromStore || []);

  const [disabled, setDisabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error } = useSWR(['/files/icd'], fetcher);

  const transformedDiagnosis = data?.map((da) => ({
    ...da,
    value: da?.type,
    label: da?.type,
  }));

  useEffect(() => {
    dispatch(handleNextPlanStep({ step: 0 }));
  });

  const handleBack = () => {
    user?.id
      ? navigate(`/dashboard/user-details/${user?.id}`)
      : navigate('/dashboard');
  };

  const saveAndContinue = async () => {
    setDisabled(true);
    setIsLoading(true);
    const formData = {
      assessment: {
        stressors: assessment?.stressors,
        shortTermGoal: {
          message: assessment?.shortTermGoal?.message,
          reductionLevel: assessment?.shortTermGoal?.reductionLevel,
          period: assessment?.shortTermGoal?.period,
        },
        longTermGoal: assessment?.longTermGoal,
      },

      careplan: {
        ...currentCarePlan,
        assessmentDescription: assessmentOverview,
        assessment: assessment?._id,
        riskToSelf,
        riskToOthers,
        diagnosis,
        stepsCompleted: {
          stepOneCompleted: true,
        },
      },
      userId: user?.id,
    };

    try {
      const { data } = await createCarePlan(formData);
      dispatch(
        setCurrentCarePlan({
          ...data.data,
          assessmentDescription: assessmentOverview,
        })
      );

      setDisabled(false);
      setIsLoading(false);
      dispatch(handleNextPlanStep({ step: 1 }));
      navigate('/create-care-plan/plan');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong');
      setDisabled(false);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      <section className={styles.topName}>
        <div className={styles.image}>
          <img src={avatar} alt="" />
          <div>
            <h1>{user?.firstName + ' ' + user?.lastName}</h1>
            <span>Care Plan</span>
          </div>
        </div>
        <GoBackButton onClick={handleBack} to={-1} />
      </section>
      <section className={styles.assessPatientRoot}>
        <h1>Assess Patient</h1>
        <span>Assess patient risks</span>
        <div className={styles.contents}>
          <div className={styles.left}>
            <div className={styles.assess}>
              <p>K10 Assessment</p>
              <div>
                <h1>{assessment?.level}</h1>
              </div>
            </div>
            <div className={styles.stressors}>
              <h1>Stressors</h1>
              <div className={styles.stressContents}>
                {Object.keys(assessment?.stressors)
                  .filter((key) => assessment?.stressors[key])
                  .map((data, index) => (
                    <div className={styles.stress}>
                      <span>{index + 1}</span>
                      <p>{data}</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.goals}>
              <h1>Short term goal</h1>
              <div className={styles.goalsContent}>
                <div className={styles.goal}>
                  <p>{assessment?.shortTermGoal?.message}</p>
                </div>
              </div>
            </div>
            <div className={styles.longGoals}>
              <h1>Long term goals</h1>
              <div className={styles.longGoal}>
                <p>{assessment?.longTermGoal}</p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.head}>
              <h1>Assessment</h1>
            </div>
            <div className={styles.rightAssess}>
              <p>{assessmentOverview}</p>
            </div>
            <div className={styles.rightDropdowns}>
              <h1>How would you access this patient?</h1>
              <div>
                <CustomSelectDropdown
                  showSearch={false}
                  value={riskToSelf}
                  label="Risk to self"
                  data={riskOptions}
                  onChange={(value) => setRiskToSelf(value)}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <CustomSelectDropdown
                  showSearch={false}
                  value={riskToOthers}
                  label="Risk to others"
                  data={riskOptions}
                  onChange={(value) => setRiskToOthers(value)}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <CustomSelectDropdown
                  showSearch={true}
                  mode="multiple"
                  value={diagnosis}
                  label="Diagnosis"
                  data={transformedDiagnosis}
                  onChange={(value) => setDiagnosis(value)}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                onClick={saveAndContinue}
                label={'Continue'}
                disabled={disabled}
                isLoading={isLoading}
                type={'button'}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Assessment;
