import React from 'react';

const PendingPatientsTable = ({ data, navigate, defaultProfilePicture }) => {
  return (
    <table>
      <thead>
        <tr className="table-head">
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>Patient</th>
          <th style={{ fontFamily: 'Avenir' }}>Email</th>
          <th style={{ fontFamily: 'Avenir' }}>Phone</th>
          <th style={{ fontFamily: 'Avenir' }}>Date Invited </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ user, assessmentDate, recentScore }) => (
          <tr className="table-row" key={user?._id}>
            <td>
              <div className="custom-table-image">
                <img
                  src={defaultProfilePicture}
                  alt={user?.firstName}
                  className=""
                />
                <p>{`${user?.firstName} ${user?.lastName}`}</p>
              </div>
            </td>
            <td>{user?.email}</td>
            <td>{user?.phoneNumber}</td>
            <td>{recentScore}</td>

            <td></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PendingPatientsTable;
