import './Navbar.scss';
import NavItem from './NavItem';
import { useState } from 'react';

// Icons
import right from '../assets/logo/right.svg';
import myPatients from '../assets/logo/patient.svg';
import cpLogo from '../assets/logo/cp.svg';
import history from '../assets/logo/history.svg';
import account from '../assets/logo/account.svg';
import billing from '../assets/logo/billing.svg';
import billingActive from '../assets/logo/billing-active.svg';
import dashboardIcon from '../assets/logo/dashboard.svg';
import defDashboardIcon from '../assets/logo/dashboard-inactive.svg';
import notification from '../assets/logo/notification.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [toggleProfile, setToggleProfile] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className="navbar-root">
      <div className="navbar-container">
        <div onClick={() => navigate('/dashboard')}>
          <img src={cpLogo} alt="cp-logo" />
        </div>

        <div className="navbar-nav-items">
          <NavItem
            to="/dashboard"
            text="Dashboard"
            icon={defDashboardIcon}
            isActive={true}
            hoveredIcon={dashboardIcon}
          />

          <NavItem
            hoveredIcon={billingActive}
            to="/dashboard/billing"
            text="Billing"
            icon={billing}
          />
        </div>

        <div className="navbar-notification">
          <img
            src={notification}
            alt="notification"
            className="notification-icon"
          />
          <div>
            <div className="navbar-profile-toggle">
              <small>{`${user.firstName} ${user.lastName}`}</small>
              <img
                src={right}
                alt="right"
                onClick={() => setToggleProfile(!toggleProfile)}
              />
            </div>
            {toggleProfile && (
              <div className="navbar-profile">
                <div className="navbar-arrow-div"></div>
                <div className="navbar-profile-container">
                  <Link className="profile" to={'/account'}>
                    Profile
                  </Link>
                  <hr className="separator" />
                  <h6 className="profile" onClick={logOut}>
                    Logout
                  </h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
