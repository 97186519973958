import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  user: {
    createdAt: null,
    dateOfApproval: null,
    email: null,
    firstName: null,
    imageUrl: null,
    isBankAccountConnected: false,
    isOtpVerified: false,
    kycStatus: null,
    lastLoggedIn: null,
    lastName: null,
    multiFactorAuth: true,
    phone: null,
    postalCode: null,
    practitionerLocations: [],
    referralCode: null,
    stripeAccountId: null,
    timezoneOffset: null,
    type: null,
    updatedAt: null,
    _id: null,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const { token, user } = action.payload;
      state.isLoggedIn = true;
      state.accessToken = token;
      state.user = user;
    },
    signUpSuccess: (state, action) => {
      console.log(action);
      const { user, token } = action.payload;
      state.user = user;
      state.accessToken = token;
    },
    kycVerification: (state, action) => {
      const { kycStatus } = action.payload;
      state.user.kycStatus = kycStatus;
    },

    logout: (state) => {
      state.email = '';
      state.password = '';
      state.isLoggedIn = false;
      state.accessToken = null;
    },
  },
});

export const { loginSuccess, logout, signUpSuccess, kycVerification } =
  authSlice.actions;

export default authSlice.reducer;
