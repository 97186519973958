import './SelectInput.scss';
import { useId } from 'react';

const SelectInput = ({ name, value, onChange, hasError }) => {
  const options = [
    { name: 'Nurse Practitional', value: 'Nurse Practitional', id: useId() },
    {
      name: 'General Practitioner',
      value: 'General Practitioner',
      id: useId(),
    },
    { name: 'Psyciatrist', value: 'Psyciatrist', id: useId() },

    { name: 'Select Provider Type', value: '', id: useId() },
  ];
  return (
    <div className={`select-input-container ${hasError ? 'error' : ''}`}>
      <select name={name} value={value} onChange={onChange}>
        {options.map((op) => (
          <option value={op.value} key={op.id}>
            {op.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
