export const PlatformTermsOfService = () => {
  return (
    <div id="intro">
      <h2>Platform Terms of Service</h2>
      <div className="content_body">
        <p>
          The Care Plan 365 Platform is a user-friendly and integrated
          management platform for Mental Health Care Plans (“MHCP”). It enables
          health care providers and professionals to collaborate and implement a
          MHCP with patients. It is a website accessible at
          <a href="https://cp365.health/">https://cp365.health/</a>
          and a patient facing mobile app (together the, "Platform").
          <br />
          These Terms set out how Care Plan 365 Pty Ltd ("Care Plan 365", "we",
          "us" or "our") offers our Platform and Services to you.
          <br />
          By accessing and using our Platform and Services, you agree to and
          consent to be bound by the Terms set out below.
        </p>
      </div>
    </div>
  );
};

export const WhoThisAppliesTo = () => {
  return (
    <div id="who_this_applies_to">
      <h2>1. Who this applies to</h2>
      <div class="content__body">
        <p>1.1 In these Terms, "you" and "Users" means:</p>
        <br />
        <ul>
          <li>
            General Practitioners using the Platform to complete MHCP’s and to
            refer Patients (“GPs”);
          </li>
          <li>
            Mental Health Professionals that receive Referrals from GPs and use
            the Platform to assist Patients with their MHCP ("Receiver");
          </li>
          <li>
            Patients who are using the Platform, and are referred via the
            Platform, for their MHCP ("Patients"); and/or
          </li>
          <li>
            a person who browses, visits or otherwise uses the Platform or the
            Services.
          </li>
        </ul>
      </div>
    </div>
  );
};

export const AboutTheseTerms = () => {
  return (
    <div id="about_these_terms">
      <h2>2. About these Terms</h2>
      <div class="content__body">
        <p>
          2.1 By registering with us and using the Platform, you indicate your
          acceptance of these Terms (including any of our applicable Charges).
          By accepting these Terms, you agree to the obligations imposed on you
          under these Terms and, in exchange, we agree to provide you with
          access to the Platform and our Services. If you do not agree to these
          Terms you should immediately cease accessing the Platform and using
          the Services.
        </p>
        <p>
          2.2 We reserve all rights to alter these Terms at our discretion. Each
          time you use the Platform or Services, it is your responsibility to be
          aware of our current terms. Your continued use of the Platform
          following the posting of changes to these Terms will be deemed your
          acceptance of those changes.
        </p>
        <p>
          2.3 These Terms, as amended by us from time to time, continue to apply
          for the duration of your use of the Platform and Services or until
          terminated in accordance with clause 11.
        </p>
      </div>
    </div>
  );
};

export const PurposeOfPlatform = () => {
  return (
    <div id="purpose_of_platform">
      <h2>3. Purpose of this platform</h2>
      <div class="content__body">
        <p>
          3.1 The purpose of the Platform is to provide an integrated MHCP
          management system that enables a MHCP to be created and worked on
          collaboratively between GPs, Patients and Receivers, including where
          appropriate, sending electronic Referrals for Patients to Receivers
          who can assist in achieving Patients’ MHCP goals (e.g. clinical and
          registered psychologists, psychiatrists, social workers and
          occupational therapists).
        </p>
        <p>
          3.2 The Platform will enable GPs, Patients and Receivers to access the
          MHCP and work collaboratively to document and track the Patient’s
          needs and progress.
        </p>
        <p>
          3.3 The Platform is to be used only for non-emergency, non-acute
          medical Referrals.
        </p>
        <p>
          3.4 If a Patient is experiencing an acute medical situation or other
          medical emergency, the relevant healthcare practitioner (e.g. GP or
          Receiver) should refer the Patient to appropriate acute medical
          services (e.g. emergency department, hospital) through means outside
          the Platform.
        </p>
      </div>
    </div>
  );
};

export const OverviewOfServices = () => {
  return (
    <div id="overview_of_services">
      <h2>4. Overview of Services</h2>
      <div class="content__body">
        <p>
          4.1 We will provide a number of Services through the Platform to Users
          including those set out in clauses 4.3, 4.4 and 4.5 the "Services".
          <br />
          4.2 We provide the Services as a platform provider and Care Plan 365
          does not in any way constitute a health care provider.
          <br />
          4.3 As a GP, you will be able to access the provider-facing part of
          the Platform to securely and digitally:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (i) add Patients and their MHCP to the Platform, as well as to send
            Referrals for your Patients;
          </li>
          <li>
            (ii) add Patient personal and health information for the purposes of
            completing the MHCP and any Formal Reviews, or sending a Referral
            with consent from your Patient;
          </li>
          <li>
            (iii)receive notifications and tracking of any Referrals that you
            have sent through the Platform via an approved workplace email
            address;
          </li>
          <li>
            (iv) send an electronic referral to a Receiver and explain the
            reasons for referring the patient, including any information about
            the patient’s condition and attach the MHCP and any other relevant
            medical documents or correspondence;
          </li>
          <li>
            (v) manage all Referrals that have been sent within the secure
            database of the Platform;
          </li>
          <li>(vi) review Referral data and clinic activity;</li>
          <li>(vii) review Treatment Reports provided by Receivers;</li>
          <li>
            (viii) access template documents to assist with Medicare approved
            focussed psychological strategies;
          </li>
          <li>
            (ix) amend the MHCP as required, including to reflect Treatment
            Reports and Formal Reviews;
          </li>
        </ul>
        <br />
        <p>
          ("
          <strong>GP Services</strong>
          ")
          <br />
          As a Receiver, you will be able to access the provider-facing part of
          the Platform to:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (x) receive Referrals, including the MHCP, from GPs electronically,
            triage the referral and respond directly to the GP and Patient
            electronically;
          </li>
          <li>
            (xi) add Patient personal and health information for the purposes of
            receiving a referral, with consent from your Patient;
          </li>
          <li>(xii) add a Treatment Report for review by the referring GP;</li>
          <li>
            (xiii) receive notifications and tracking of any Referrals that you
            have received through the Platform and via an approved workplace
            email address;
          </li>
          <li>
            (xiv) triage, accept, forward, archive, return, schedule an
            appointment or waitlist any Referrals that have been sent; and
          </li>
          <li>(xv) review Referral data and clinic activity,</li>
        </ul>
        <br />
        <p>
          ("
          <strong>Receiver Services</strong>
          ")
          <br />
          4.4 As a Patient, you will be able to:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (a) access, view and download a PDF copy of your MHCP and any
            referrals, through the Patient-facing part of the Platform;
          </li>
          <li>(b) complete mental health screening questionnaires;</li>
          <li>
            (c) receive notifications of any changes to your MHCP within the
            Platform; and
          </li>
          <li>
            (d) receive notifications and tracking of any referrals that have
            been made on your behalf from the GP to the Receiver via email and
            within the Platform.
          </li>
        </ul>
      </div>
    </div>
  );
};

// UsingPlatformAndServices component
export const UsingPlatformAndServices = () => {
  return (
    <div id="using_the_platform_and_services">
      <h2>Using the platform and Services</h2>
      <div class="content__body">
        <p>
          5.1 Any information that we provide is supplied in good faith, but we
          do not guarantee the accuracy or completeness of any information
          provided by us or any third party, including any User. You agree that
          it is your responsibility to make enquiries as to the accuracy or
          completeness of any information inputted into the Platform.
        </p>
        <br />
        <p>
          5.2 You agree that you will bear the sole responsibility for any
          activity that occurs on your Account. You must keep your Account
          details and password secure, as you are responsible for any activity
          on your Account (whether undertaken by you or anyone else). You agree
          to notify us immediately if you become aware of or suspect any
          security breach or unauthorised use of your password or Account.
        </p>
        <br />
        <p>
          5.3 The Platform may contain links to other websites. Those links are
          provided for convenience only and may not be current. Any hyperlinks
          or banners advertising other websites on the Website, or external
          websites that advertise the Website, are not subject to our control or
          privacy standards, policies and procedures. We will not be responsible
          for the content or privacy practices associated with such linked
          websites and we recommend that you make your own enquiries as to the
          privacy and other policies of those third parties.
        </p>
        <br />
        <p>
          5.4 You must take precautions to ensure that when accessing the
          Platform and Services, you do not expose your telecommunications or
          computer systems to viruses, malware or other forms of interference
          that may damage your telecommunications or computer systems. We are
          not responsible for any such damage to your telecommunications or
          computer systems which may arise in connection with your access to the
          Website and use of the Services.
        </p>
        <br />
        <p>
          5.5 You may access support services via the Platform and by submitting
          enquiries through the “ Help” section provided on the Platform.
        </p>
        <br />
        <p>
          5.6 We may, at our absolute discretion, terminate, suspend or delete
          your Account or restrict your access to the Services (or any part of
          the Services) for any reason without notice. This includes for
          security, technical, maintenance, legal or regulatory reasons or due
          to any breach of these Terms. If we do this, you may be prevented from
          accessing all or parts of the Services, your Account details or other
          content contained in your Account. We will not be liable to you for
          doing so.
        </p>
        <br />
        <p>
          5.7 If you fail to comply with these Terms, we may, at our absolute
          discretion and without liability:
        </p>
        <br />
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (a) immediately, temporarily or permanently withdraw your right to
            access and use the Services and the Platform (including the deletion
            of your Account);
          </li>
          <li>(b) take any other legal action against you; or</li>
          <li>
            (c) refer or report any suspected fraudulent, abusive or illegal
            activity to relevant law enforcement authorities.
          </li>
        </ul>
      </div>
    </div>
  );
};

// Registration component
export const Registration = () => {
  return (
    <div id="registration">
      <h2>Registration</h2>
      <div class="content__body">
        <p>
          6.1 To access the Services, you must register via the Platform and
          pass our Know Your Client (“KYC”) requirements. This involves
          providing us with the relevant information required to set up your
          Account. This includes for GPs and Receivers:
        </p>
        <br />
        <ul style={{ marginLeft: '20px' }}>
          <li>(a) Name</li>
          <li>
            (b) AHPRA registration number or AASW accreditation (as applicable);
          </li>
          <li>(c) Medicare Provider number;</li>
          <li>(d) Contact details;</li>
          <li>(e) Occupation details;</li>
          <li>(f) Billing information (including Credit Card Details);</li>
        </ul>
        <p>and for Patients includes:</p>
        <ul style={{ marginLeft: '20px' }}>
          <li>(g) Name;</li>
          <li>(h) Date of Birth; and</li>
          <li>(i) Medicare Number,</li>
          <li>(j) Contact details;</li>
          <li>(k) Next of Kin Contact details</li>
        </ul>
        <p>
          and any other information that may be required by us or the provider
          for the purposes of submitting and/or receiving a Referral
          (Registration Data).
        </p>
        <p>
          6.2 We reserve the right to decline your registration request if you
          do not pass our verification process. In particular, all GP’s and
          Receivers must be registered with AHPRA or AASW (as applicable) and no
          Referrals can be sent using the Platform until we can verify this
          information.
        </p>
        <p>
          6.3 If you provide Registration Data to us, you represent and warrant
          to us that the Registration Data provided is true, complete, accurate
          and up to date, and you undertake to maintain the truthfulness,
          completeness and accuracy of your Registration Data as necessary
          and/or as required by us.
        </p>
        <p>
          6.4 If you provide us with Registration Data, you consent to the
          following:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (a) we may use your Registration Data as required for the purposes
            of providing the Services
          </li>
          <li>
            (b) you will receive an email from us confirming the details of your
            registration and a link for access to your Account, providing you
            with necessary information relating to your access and use of the
            Services and any subscriptions to the Services made through your
            Account; and
          </li>
          <li>
            (c) from time to time, we may email you regarding our Services or
            third-party products and services which we believe may be of
            interest to you, such as new products, services, features, special
            offers and updated information. The emails may contain code that
            enables our database to track your usage of the emails, including
            whether the email was opened and/or what links (if any) were
            clicked. We may combine that information with other information
            which we have about you and may use that information to improve your
            site experience and/or provide customised email communications to
            you.
          </li>
        </ul>
        <p>
          6.5 You acknowledge that we may use your Registration Data and any
          other personal information provided to us in accordance with our
          Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export const PrivacyAndConsent = () => {
  return (
    <div id="privacy_and_consent">
      <h2>Privacy and Consent</h2>
      <div class="content__body">
        <p>
          7.1 To engage with, refer or receive a Patient via the Platform, you
          must disclose the health information of that Patient. In providing or
          receiving the health information of a Patient via the Platform, you
          warrant that you have sought all required consents (including verbal
          and/or written consent) from the Patient (or a parent or guardian of a
          Patient who is a minor) to do so and that you have otherwise fully
          complied with any applicable Privacy Laws and all other relevant
          legislation and regulations pertaining to the collection, storage, use
          and disclosure of health information. You agree to indemnify us for
          any liability, costs and expenses (including our reasonable legal
          costs) which we incur as a result of a breach by you of your privacy
          obligations.
        </p>
        <p>
          7.2 We are committed to protecting privacy and personal information
          (including health data). Further details about our practices relating
          to the collection, use, disclosure and storage of personal information
          can be found in our Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export const ChargesAndFees = () => {
  return (
    <div id="how_much_we_charge">
      <h2>How much do we charge for the Services</h2>
      <div class="content__body">
        <p>
          8.1 The Platform may be accessed for free by Referrers and Patients
          with no charges applied unless otherwise agreed with us.
        </p>
        <p>
          8.2 Receiver access to the Platform is subject to certain fees,
          including:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>(a) implementation costs (if applicable);</li>
          <li>(b) ongoing Subscription Fees; and</li>
          <li>
            (c) any other costs as agreed between the GP and Care Plan 365,
            (“Charges”).
          </li>
        </ul>
        <br />
        <p>8.3 All Charges are exclusive of GST unless otherwise specified.</p>
        <p>
          All Subscription Fees are listed on the Platform and are available at
          [insert Link]. The Subscription Fees may be subject to change by us.
          We will provide written notice whenever the fees will be subject to
          change by sending an email to the email address on the GP account.
        </p>
      </div>
    </div>
  );
};

export const PaymentProcess = () => {
  return (
    <div id="payment_process">
      <h2>Payment Process</h2>
      <div class="content__body">
        <p>
          9.1 All Charges are processed in advance on a monthly, annual or
          otherwise agreed basis, depending on the Subscription Term.
        </p>
        <p>
          9.2 We use Payment Processing Services provided by Stripe to carry out
          payment of the Charges. Specifically, transactions will be processed
          via credit card or direct debit.
        </p>
        <p>
          9.3 Payments submitted via Stripe are subject to the terms and
          conditions published on the Stripe website (collectively, the “Stripe
          Services Agreement”). By placing an order and using Stripe to process
          payments you agree to be bound by the Stripe Services Agreement, which
          may be modified by Stripe from time to time. As a condition of
          enabling Payment Processing Services through Stripe, you agree to
          provide us with accurate and complete information about you and your
          business, and you authorise us to share it and transaction information
          related to your use of the Payment Processing Services provided by
          Stripe in accordance with our Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export const Obligations = () => {
  return (
    <div id="what_are_your_obligations">
      <h2>What are your obligations</h2>
      <div class="content__body">
        <p>
          10.1 You must at all times comply with your obligations under these
          Terms and fulfil your obligations in a timely manner. A failure to
          comply with these obligations may, at our absolute discretion, result
          in the suspension or termination of your Account.
          <br />
          10.2 You may not use a Platform other than for its intended purpose as
          set out in clause 3 or as otherwise communicated to you by us.
          <br />
          10.3 You must not advocate, support or practise discrimination based
          on race, religion, age, national origin, language, gender, sexual
          orientation, or mental or physical handicap.
          <br />
          10.4 While acting as a GP you must:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>(a) act in the best interests of the Patient;</li>
          <li>
            (b) collect only relevant health information in a Referral that is
            sent over the Platform, and ensure that all relevant Privacy Laws
            are complied with in this process;
          </li>
          <li>
            (c) send Referrals through the Platform to appropriate Mental Health
            Professionals (Receivers);
          </li>
          <li>
            (d) only disclose and share a Patient’s health information through
            the Platform to Receivers and any other Users in relation to a
            Referral and with the full consent of the Patient; and
          </li>
          <li>
            (e) where relevant and agreed by us, only send Referrals to us or
            Mental Health Professionals external to the platform as deemed
            appropriate by you in the care of the Patient.
          </li>
        </ul>
        <p>10.5 While acting as Receiver you must:</p>
        <ul style={{ marginLeft: '20px' }}>
          <li>(a) act in the best interests of the Patient;</li>
          <li>
            (b) act on any Referral received through the Platform as is expected
            of Mental Health Professionals in their line of duty;
          </li>
          <li>
            (c) be responsible for managing a Referral received through the
            Platform and any subsequent actions or necessary care required for
            the Patient, including without limitation in respect of any actions
            or necessary care or communications with healthcare providers
            external to the Platform or any of its users; and
          </li>
          <li>
            (d) communicate appropriately with the GP, the Patient and any other
            relevant health and care organisations, providers and professionals
            external to the Platform in the best interests of the Patient.
          </li>
        </ul>
        <p>
          10.6 At all times GP’s and Receivers must act in the best interest of
          the relevant Patient and may communicate outside of the Platform as
          required to advance the Referral.
        </p>
        <p>
          10.7 Any complaints regarding abuse or derogatory behaviour on the
          part of any User may see such User(s) removed from the Platform,
          result in the termination or suspension of their account and/or lead
          to restricted access to the Services, at our absolute discretion.
        </p>
        <p>
          10.8 You acknowledge and agree that, in order for us to be able to
          provide the Services to you without interruption you will, at your
          cost, at all times:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (a) co-operate with and reasonably assist us to provide the
            Services;
          </li>
          <li>
            (b) promptly provide us with full and accurate information, data and
            explanations with regard to any dispute, complaint, issue or
            otherwise that may arise, as and when required; and
          </li>
          <li>
            (c) inform us of any abusive, allegedly abusive, or unlawful
            behaviour from any User on the Platform.
          </li>
        </ul>
        <p>
          10.9 You must procure all necessary consents or authorisations from
          any third parties which may be required from time to time in order for
          us to be able to provide the Services to you.
        </p>
      </div>
    </div>
  );
};

export const Termination = () => {
  return (
    <div id="termination">
      <h2>Termination of access to platform and/or Services</h2>
      <div class="content__body">
        <p>
          11.1 In addition to our rights under clause 5 and any other agreement
          between us, we may, at our absolute discretion, terminate these Terms
          or cease to supply you with the Services, if:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (a) it transpires that you have provided false or misleading
            information via the Platform;
          </li>
          <li>
            (b) you are found by us to be offensive or abusive to any User;
          </li>
          <li>
            (c) you accumulate repeated reports of User dissatisfaction, to be
            determined at our discretion;
          </li>
          <li>
            (d) you no longer hold all legal authorisations required to operate
            via the Platform. For example, if a GP or Receiver is found not to
            be a registered Mental Health Professional and/or the relevant AHPRA
            registration number, or AASW accreditation, is invalid, expired or
            does not exist;
          </li>
          <li>
            (e) you do not comply with your obligations under these Terms; or
          </li>
          <li>
            (f) it is commercially reasonable for us to cease providing the
            Services to you (at our discretion acting reasonably).
          </li>
        </ul>
        <p>
          11.2 We may terminate your access to the Platform or our supply of
          Services to you immediately if you fail to pay any relevant invoices
          and such sum remains unpaid for 14 days after notice has been given to
          you that such sum has not been paid.
        </p>
        <p>
          11.3 Without limiting any other clause in these Terms, if a Receiver
          fails to address a Referral sent to you through the Platform and does
          not communicate adequately or in a timely fashion with the GP or
          Patient, we may immediately terminate these Terms or cease to supply
          you with the Services.
        </p>
        <p>
          11.4 Unless otherwise agreed with us in a separate agreement, a
          Patient or GP may terminate the arrangement at any time by ceasing to
          use the Platform and notifying us. Any Account which is inactive for
          90 days will be temporarily disabled with Patient data stored in
          accordance with the Privacy Policy.
        </p>
        <p>
          11.5 If applicable, at the end of the Subscription Term the
          arrangement for GP’s will automatically roll over for a further 90 day
          Subscription Term unless the GP notifies us in writing at least 30
          days prior to the Subscription end date. If a GP wishes to terminate
          the arrangement before the end of their Subscription Term, we will be
          entitled to charge a break fee of up to 100% of the outstanding
          amounts that would be due under the remaining Subscription Term (in
          our sole discretion).
        </p>
        <p>
          11.6 If your access to the Services is terminated for any reason, we
          will be entitled to payment for all outstanding Charges properly
          incurred by us up to the date of termination and any Charges incurred
          during any applicable notice period or otherwise specified in these
          Terms.
        </p>
        <p>
          11.7 Following termination, your Account will be closed and GPs and
          Receivers will not be able to send or receive any further Referrals or
          information through the Platform. You will continue to have access to
          any pre-existing Referrals or information in your Account or through
          the Platform for 30 days following termination. After this 30 day
          period you will not have any access to Referrals or information on the
          Platform and we will store all Patient data in accordance with the
          Privacy Policy. It is your responsibility to download and store any
          Referrals required for your own record keeping obligations.
        </p>
      </div>
    </div>
  );
};

export const Warranties = () => {
  return (
    <div id="warranties">
      <h2>Warranties</h2>
      <div class="content__body">
        <p>
          12.1 We will, within a reasonable period of time, investigate any
          alleged error or another issue regarding any of the Services, provided
          that you notify us in writing within seven (7) days of becoming aware
          of the error or other issue and give us all necessary information to
          be able to investigate the error or other issue. Notwithstanding, you
          agree that our liability will be limited in any event to the right to
          attempt redelivery of the Services to you, and otherwise in the manner
          set out in these Terms.
          <br />
          You agree to use your reasonable endeavours to ensure that the
          information you supply to any User or to us is complete and accurate
          and notify us (and, if relevant, the User(s)) in writing if there is
          any change to the information supplied.
          <br />
          12.2 Except as provided in these Terms, no further warranty,
          condition, undertaking or term, express or implied, statutory or
          otherwise, as to the condition, quality, performance or fitness for
          purpose of the Services provided in accordance with these Terms is
          given by us, other than as required by law. All implied warranties are
          hereby excluded.
          <br />
          12.3 Nothing contained in these Terms excludes, restricts or modifies
          the application of any condition, warranty or other obligation, the
          exercise of any right or remedy, or the imposition of any liability
          under the Competition and Consumer Act 2010 (Cth) or any other
          national, state or territory legislation where to do so is unlawful.
        </p>
      </div>
    </div>
  );
};

export const Liability = () => {
  return (
    <div id="liability">
      <h2>Liability</h2>
      <div class="content__body">
        <p>
          13.1 Care Plan 365 does not provide any direct patient care, medial or
          other health care interventions, diagnosis, advice or treatment
          whatsoever.
        </p>
        <p>
          13.2 You acknowledge that the responsibility of the health care and
          medical treatment of a Patient is that of the relevant GP, and if
          accepted, the relevant Receiver, and not the responsibility of Care
          Plan 365 or its personnel under any circumstances.
        </p>
        <p>
          13.3 By including a GP or Receiver on the Platform, we do not make any
          warranty or guarantee regarding:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>(a) the work or advice provided by such Users;</li>
          <li>
            (b) whether such Users will respond to Referrals or enquiries on the
            Platform or the time within which such responses will be made;
          </li>
          <li>
            (c) the appropriateness of any MHCP or Referrals from such Users; or
          </li>
          <li>(d) any other information regarding Users on the Platform.</li>
        </ul>
        <p>
          13.4 To the extent permitted by law, we will not be liable for any
          loss, expenses, liabilities, costs or damage caused by viruses, system
          failures or other technologically harmful material that may infect
          your computer equipment, computer programs, data or other proprietary
          material due to your use of any part of the Platform or downloading of
          any material or content posted on it, or on any website linked to it.
          We recommend that you have up-to-date virus checking software
          installed.
        </p>
        <p>
          13.5 To the fullest extent permitted by law, we are not responsible
          for dealing with, and not liable for, any disputes or complaints made
          by you or any User in relation to the MHCP, Referrals or information
          on, or via, the Platform (including any dispute or complaint regarding
          Patient treatment).
        </p>
        <p>
          13.6 You agree that we will not be liable for any indirect,
          consequential, special or exemplary losses, expenses or liabilities or
          any loss of profits, loss of revenue, economic loss, loss of goodwill,
          loss, corruption or alteration of data, failure to realise anticipated
          savings, insurance costs, medical negligence claims, medical or
          healthcare costs, loss of opportunity, expectation loss or loss of
          production arising out of, or in connection with, the provision or use
          of the Services, the Platform or these Terms.
        </p>
        <p>
          13.7 You agree that, in any event, our maximum aggregate liability to
          you under these Terms will be the total Charges that you have paid in
          the 90 days preceding the claim.
        </p>
        <p>
          13.8 You acknowledge and agree that the limitations of liability
          contained in this clause are a fair and reasonable allocation of the
          commercial risk between the parties.
        </p>
        <p>
          13.9 This clause 13 survives termination or expiry of these Terms.
        </p>
      </div>
    </div>
  );
};

// Indemnity component
export const Indemnity = () => {
  return (
    <div id="indemnity">
      <h2>Indemnity</h2>
      <div class="content__body">
        <p>
          14.1 To the fullest extent permitted by law, you agree that you waive,
          release, discharge and relinquish any and all claims that you have now
          or may have against us which are connected with, arise out of, relate
          to or are incidental to the provision of the Platform and your use of
          the Services.
        </p>
        <p>
          14.2 You indemnify us, and hold us harmless, from and against any and
          all claims, loss, damage, taxes, liabilities and/or expenses that may
          be incurred by us arising out of, or in connection with, your use of
          the Platform, the Services and any breach by you of these Terms.
        </p>
        <p>
          14.3 You agree and acknowledge that we will not be liable or
          responsible for any loss or damage suffered by any User due to your
          actions, and you will indemnify us from and against any and all claims
          by any User in relation to any and all content created by you or any
          actions by you.
        </p>
        <p>
          14.4 In providing or receiving personal or health information of a
          Patient via the Platform, you warrant that you have sought all
          required consents from the Patient to do so and that you have
          otherwise fully complied with any applicable Privacy Laws and all
          other relevant legislation and regulations pertaining to the
          collection, storage, use and disclosure of health information
          including all applicable legislation and regulations in your
          jurisdiction of practice. You agree to indemnify us for any liability,
          costs and expenses (including our reasonable legal costs) which we
          incur as a result of a breach by you of your privacy obligations.
        </p>
        <p>
          14.5 This clause 14 survives termination or expiry of these Terms.
        </p>
      </div>
    </div>
  );
};

// IntellectualProperty component
export const IntellectualProperty = () => {
  return (
    <div id="intellectual_property">
      <h2>Intellectual Property</h2>
      <div class="content__body">
        <p>
          15.1 You acknowledge that all Intellectual Property Rights in the
          Services and Platform are the property of us (or our licensors) and
          your use of, and access to, the Services, and Platform does not give
          you any rights, title or interest in or to the Services, or Platform.
          Unless expressly authorised either under these Terms or otherwise by
          the licensors, you may not reproduce, adapt, modify, display, perform
          or distribute the Services or Platform or any part of the Services or
          Platform.
        </p>
        <p>
          15.2 You may not modify or copy the layout or appearance of the
          Services or any computer software or code contained in the Services,
          nor may you decompile or disassemble, reverse engineer or otherwise
          attempt to discover or access any source code related to the Services.
        </p>
        <p>
          15.3 We grant you, for the duration of these Terms as determined in
          accordance with clause 2.3, a non-exclusive, non-transferable and
          revocable licence to use the Intellectual Property Rights embodied in
          the Services only to the extent necessary for you to use the Services
          and Platform for your business purposes.
        </p>
        <p>
          15.4 By uploading, posting, transmitting or otherwise making available
          any Material via the Services or Platform, you:
        </p>
        <ul style={{ marginLeft: '20px' }}>
          <li>
            (a) grant to us a non-exclusive, worldwide, royalty-free, perpetual,
            irrevocable licence to use, publish, reproduce and otherwise exploit
            that Material in any form to enable us to provide the Services and
            Platform and unconditionally waive any moral rights that you might
            have in respect of that Material; and
          </li>
          <li>
            (b) represent and warrant that you either own the Intellectual
            Property Rights in that Material or have the necessary permission to
            upload, post, transmit or otherwise make available that Material via
            the Platform.
          </li>
        </ul>
      </div>
    </div>
  );
};

export const UnavoidableEvents = () => {
  return (
    <div id="unavoidable_events">
      <h2>Unavoidable Events</h2>
      <div class="content__body">
        <p>
          16.1 We will not be liable to you if we are prevented from, or delayed
          in, providing the Services due to acts, events, omissions or accidents
          beyond our reasonable control (“Unavoidable Events”). Where an
          Unavoidable Event occurs, we will attempt to recommence provision of
          the Services as soon as reasonably practicable.
        </p>
      </div>
    </div>
  );
};

export const DisputeResolution = () => {
  return (
    <div id="dispute_resolution">
      <h2>Dispute Resolution</h2>
      <div class="content__body">
        <p>
          17.1 If you have a complaint about the performance of these Terms or
          the Services, you will contact us at{' '}
          <a href="mailto:hello@cp365.health">hello@cp365.health</a> in the
          first instance and allow us reasonable time to consider your
          complaint, determine a possible solution and notify you of the
          solution.
        </p>
        <p>
          17.2 This clause 17 survives the expiry or termination of these Terms.
        </p>
      </div>
    </div>
  );
};

export const LinkingToPlatform = () => {
  return (
    <div id="linking_to_platform">
      <h2>Linking to the Platform</h2>
      <div class="content__body">
        <p>
          18.1 You may link to the Platform, provided that you do so in a way
          that is fair and legal and does not damage, or take advantage of, our
          goodwill. You must not establish a link in such a way as to suggest
          any form of association, approval or endorsement by us where none
          exists.
        </p>
        <p>
          18.2 We reserve the right to withdraw linking permission under this
          clause 18 by updating these Terms or by notice to you.
        </p>
      </div>
    </div>
  );
};

export const GeneralSection = () => {
  return (
    <div id="general_section">
      <h2>General</h2>
      <div class="content__body">
        <p>
          19.1 We may assign, sub-contract, or otherwise transfer any or all of
          its rights and/or obligations under these Terms. You may only assign,
          subcontract, or otherwise transfer any or all of your rights and/or
          obligations with our prior written consent, which can be refused at
          our absolute discretion.
          <br />
          19.2 A party’s failure or delay to exercise a power or right does not
          operate as a waiver of that power or right. The exercise of a power or
          right does not preclude its future exercise or the exercise of any
          other power or right.
          <br />
          19.3 We and you are independent. No contractor, agency, partnership,
          joint venture, fiduciary or employment relationship is created by
          these Terms. Neither we nor you will have, nor represent that we or
          you have, any authority to make any commitments on the other party's
          behalf except expressly pursuant to these Terms.
          <br />
          19.4 Any provision of these Terms that is found to be void or
          unenforceable will, to the extent that it is void or unenforceable, be
          severed from these Terms without affecting the enforceability or
          validity of any other provisions.
          <br />
          19.5 These Terms are governed by the laws of Victoria, Australia. The
          parties unconditionally submit to the exclusive jurisdiction of the
          courts exercising jurisdiction there.
          <br />
          19.6 These Terms and any document expressly incorporated in it
          contains the whole agreement between us and you in respect of the
          subject matter of these Terms and supersedes and replaces any prior
          written or oral agreements, representations or understandings between
          the parties. The parties confirm that they have not relied on any
          representation that is not expressly incorporated into these Terms.
        </p>
      </div>
    </div>
  );
};

export const DefinitionsSection = () => {
  return (
    <div id="definition_section">
      <h2>Definitions</h2>
      <div class="content__body">
        <p>
          <strong>“AASW”</strong> means the Australian Association of Social
          Workers;
          <br />
          <strong>“Account”</strong> means your Care Plan 365 account that
          facilitates your use of the Platform;
          <br />
          <strong>"AHPRA"</strong> means the Australian Health Practitioner
          Regulation Agency;
          <br />
          <strong>“Charges”</strong> means the charges set out in clause 8,
          calculated at the rates set out in clause 8 or as otherwise agreed by
          us or published by us on the Platform from time to time;
          <br />
          <strong>“Formal Review”</strong> means a formal review between a GP
          and Patient following completion of the MHCP as required by the
          Medicare Benefits Scheme;
          <br />
          <strong>“GP”</strong> means a general practitioner who is registered
          by the AHPRA;
          <br />
          <strong>“Intellectual Property Rights”</strong> means all present and
          future intellectual and industrial property rights throughout the
          world of whatever nature (whether or not registered or registrable),
          including, but not limited to, all rights in respect of technical
          information, know-how, copyright, trade marks, designs, patents,
          domain names, business names, logos, drawings, trade secrets, the
          right to have confidential information kept confidential or other
          proprietary rights, or any rights to registration of such rights;
          <br />
          <strong>“Platform”</strong> means our user-friendly and integrated
          management platform for Mental Health Care Plans (MHCP). It enables
          General Practitioners to complete a MHCP with their patients that can
          be worked on collaboratively, via a referral system, with Mental
          Health Professionals (e.g. clinical and registered psychologists,
          social workers and occupational therapists). It is a web-based
          platform that is accessible at
          <a href="https://cp365.health/">https://cp365.health/</a>{' '}
          ("Platform");
          <br />
          <strong>“Material”</strong> means any information, data, source codes,
          drawings, content, text or images in any form (whether visible or
          not), audio recordings, video recordings, lists, sound, video, links,
          layout, look and feel, control features and interfaces contained on
          the Platform or otherwise displayed, uploaded or published on, or via,
          the Platform;
          <br />
          <strong>“Mental Health Professional”</strong> means a AHPRA or AASW
          registered practitioner (as applicable) that practices as a GP,
          clinical psychologist or allied mental health professional (ie,
          registered psychologists, and appropriately trained social workers or
          occupational therapists) providing focussed psychological strategies
          (FPS) services;
          <br />
          <strong>“MHCP”</strong> means a Mental Health Care Plan;
          <br />
          <strong>“Patient”</strong> means a patient who is referred via the
          Platform;
          <br />
          <strong>“Payment Processing Services”</strong> means any services that
          we provide pursuant to these Terms which are to be used to process
          payments in relation to the Charges;
          <br />
          <strong>“Privacy Laws”</strong> means the Privacy Act 1988 (Cth), and
          any other applicable Australian privacy legislation including without
          limitation any health-specific privacy or data legislation;
          <br />
          <strong>“Privacy Policy”</strong> means our privacy policy, available
          at: https://cp365.health/privacy-policy;
          <br />
          <strong>“Receiver”</strong> means a Mental Health Professional who
          receives a Referral;
          <br />
          secondary health care provider that receives Referrals for Patients
          from primary health care providers via the Platform;
          <br />
          <strong>“Referral”</strong> means a request by a GP to a Mental Health
          Professional for investigation, opinion, treatment and/or management
          of a mental health condition of a Patient;
          <br />
          <strong>“Registration Data”</strong> means information provided by you
          to us for the purposes of your registration to access certain sections
          of the Platform;
          <br />
          <strong>“Services”</strong> refers to the Platform and any associated
          services we provide as set out in clause 4 pursuant to these Platform
          Terms or Service;
          <br />
          <strong>“Subscription”</strong> means an active subscription in
          respect of which a GP pays the Subscription Fee for access to the
          Services on the basis of a Subscription Term, or as otherwise agreed
          between the GP and Care Plan 365 from time to time;
          <br />
          <strong>“Subscription Fee”</strong> means the charges charged every
          Subscription Term agreed between the GP and Care Plan 365, or
          otherwise accessible via the Platform, and which may be updated or
          amended by us from time to time;
          <br />
          <strong>“Subscription Term”</strong> means the period of the
          subscription to the Platform as agreed between the GP and Care Plan
          365, which may be three (3) months, six (6) months, twelve (12) months
          or twenty-four (24) months in length unless we agree in writing for
          different time period;
          <br />
          <strong>“Terms”</strong> means these Platform Terms of Service, as may
          be amended by us from time to time, at our discretion;
          <br />
          <strong>“Treatment Report”</strong> means a report provided by a
          Receiver to the referring GP on completion of a course of treatment as
          required by the Medicare Benefits Scheme; and
          <br />
          <strong>“User”</strong> means any person or entity using the Services,
          whether they are a GP, Receiver, Patient, or a person who browses,
          visits or otherwise uses the Platform or the Services.
          <br />
        </p>
      </div>
    </div>
  );
};
