import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './profileDisplayCard.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from './../FormInput';
import defaultImage from '../../assets/logo/userAvatar.svg';
import Button from 'components/Button';

const ProfileDisplayCard = () => {
  const [practitioner, setPractitioner] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const { user } = useSelector((state) => state.auth) || {};

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    suburb: Yup.string().required('Suburb is required'),
    postalCode: Yup.string().required('Postal code is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      suburb: '',
      postalCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      // Handle form submission here
    },
  });
  console.log(selectedFile, 'selectedFile');

  const { errors } = formik;

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Optionally, you can perform additional logic with the file
  };

  return (
    <div className={styles['profile_wrapper']}>
      <div className={styles['profile_header']}>
        <div className={styles['img_container']}>
          <img
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile)
                : practitioner.imageUrl || defaultImage
            }
            alt="profile_pic"
          />
        </div>

        <div className={styles['bio_item']}>
          <label htmlFor="fileInput" className={styles['picture_btn']}>
            Upload Picture
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileInputChange}
            style={{ display: 'none' }}
          />
        </div>
      </div>

      <div className={styles['personal_details']}>Personal Details</div>
      <form className={styles['profile_input']} onSubmit={formik.handleSubmit}>
        <div className={styles['input-container']}>
          <div className={styles['input-column']}>
            <label>First Name</label>
            <Input
              name="firstName"
              value={user?.firstName}
              onBlur={formik.handleBlur('firstName')}
              onChange={formik.handleChange}
              error={formik.touched.firstName && formik.errors.firstName}
            />
          </div>

          <div className={styles['input-column']}>
            <label>Last Name</label>
            <Input
              name="lastName"
              value={user?.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && formik.errors.lastName}
            />
          </div>

          <div className={styles['input-column']}>
            <label>Email Address</label>
            <Input
              name="email"
              value={user?.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur('Email address')}
              error={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className={styles['input-column']}>
            <label>Phone Number</label>
            <Input
              name="phoneNumber"
              value={user?.phoneNumber}
              onChange={(value) => {
                formik.handleChange('phoneNumber')(value);
              }}
              onBlur={formik.handleBlur('phoneNumber')}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
          </div>

          <div className={styles['input-column']}>
            <label>Suburb</label>
            <Input
              name="suburb"
              value={formik.values.suburb}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur('suburb')}
              error={formik.touched.suburb && formik.errors.suburb}
            />
          </div>

          <div className={styles['input-column']}>
            <label>Postal Code</label>
            <Input
              name="postalCode"
              value={user?.postalCode}
              onChange={(value) => {
                formik.handleChange('postalCode')(value);
              }}
              onBlur={formik.handleBlur('postalCode')}
              error={formik.touched.postalCode && formik.errors.postalCode}
            />
          </div>
          <div className={styles['input-column-register']}>
            <label>Registration Number</label>
            <Input
              name="RegistrationNumber"
              value={user?.providerNumber}
              onChange={(value) => {
                formik.handleChange('postalCode')(value);
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileDisplayCard;
