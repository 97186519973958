import React, { useState, useEffect } from 'react';
import Button from './Button';
import Input from './FormInput';
import './SignUpForm.scss';
import StatusIndicator from './StatusIndicator';
import { Link } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import CustomCountrySelect from './CountrySelect';
import '../global-styles.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SelectInput from './SelectInput';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { register } from '../redux/actions/authAction';

const SignUpForm = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = useState(null);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    // Define your validation rules for each input field
    firstName: Yup.string().required(),
    email: Yup.string().email().required(),
    lastName: Yup.string().required(),
    middleName: Yup.string(),
    providerType: Yup.string().required(),
    password: Yup.string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number'
      ),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    // Add more fields and validation rules as needed
  });

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      confirmPassword: '',
      providerType: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
      values.phone = phoneNumber;
      try {
        setLoading(true);
        await dispatch(register(values));
        setLoading(false);
        setErrorMessage(null);
        navigate('/verify-account');
      } catch (error) {
        setLoading(false);
        const errorMessage =
          error?.response?.data?.message ||
          'There was an error. Please try again later';
        setErrorMessage(errorMessage);
      }
    },
  });

  const handlePhoneInputChange = (value) => {
    setPhoneNumber(value);
  };
  const { values } = formik;
  const password = values.password;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[@$!%*?&]/.test(password);
  const confirmPassword = values.confirmPassword;

  useEffect(() => {
    if (password.trim() !== '' && confirmPassword.trim() !== '')
      setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  return (
    <form className="sign-up-form-container" onSubmit={formik.handleSubmit}>
      <div className="sign-up-form-inner-conatiner">
        <div className="sign-up-form-header">
          <h3>Let us get to know you</h3>
          <p>Name should match AHPRA registration.</p>
        </div>
        {error && (
          <div className="form-action-message">
            <p>{error}</p>
          </div>
        )}
        <div className="sign-up-form-input-container">
          <div className="input-row">
            <div className="input-container">
              <Input
                label="First Name"
                name="firstName"
                type="text"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={!!formik.errors.firstName}
              />
            </div>

            <div className="input-container">
              <Input
                label="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                hasError={!!formik.errors.lastName}
              />
            </div>
          </div>
          <div className="input-column">
            <Input
              label={'Middle Name'}
              name="middleName"
              type="text"
              value={formik.values.middleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={!!formik.errors.middleName}
            />
          </div>
          <div className="input-column">
            <Input
              label={'Email Address'}
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={!!formik.errors.email}
            />
          </div>

          <div className="input-column">
            <PhoneInput
              countrySelectComponent={CustomCountrySelect}
              className="phone-input"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={handlePhoneInputChange}
            />
          </div>

          <SelectInput
            name="providerType"
            value={formik.values.providerType}
            onChange={formik.handleChange}
            hasError={!!formik.errors.providerType}
          />

          <div className="input-column">
            <Input
              label={'Set Password'}
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={!!formik.errors.password}
            />
          </div>
          <div className="input-column">
            <Input
              label={'Confirm Password'}
              name="confirmPassword"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={!!formik.errors.confirmPassword}
            />
          </div>

          <div className="sign-up-form-password-check">
            <div className="password-check-container mb">
              <StatusIndicator
                text="Uppercase letter"
                state={hasUpperCase ? 'green' : 'grey'}
              />
              <StatusIndicator
                text="Lowercase letter"
                state={hasLowerCase ? 'green' : 'grey'}
              />
              <StatusIndicator
                text="Number"
                state={hasNumber ? 'green' : 'grey'}
              />
            </div>
            <div className="password-check-container">
              <StatusIndicator
                text="Special character"
                state={hasSpecialChar ? 'green' : 'grey'}
              />
              <StatusIndicator
                text="Password Match"
                state={passwordsMatch ? 'green' : 'grey'}
              />
            </div>
          </div>
          <div className="sign-up-form-button-container">
            <Button
              label="Sign Up"
              type="submit"
              disabled={!formik.isValid}
              isLoading={loading}
            />
          </div>
          <div className="have-account">
            <div>
              <small>Have an account?</small>
              <Link className="nav-link" to="/">
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
