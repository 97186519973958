import React, { useEffect, useState } from 'react';
import avatar from '../../../../assets/logo/userAvatar.svg';
import checkIcon from '../../../../assets/logo/icon-check.svg';

import styles from './billingFilled.module.scss';
import GoBackButton from '../../../GoBackButton/GoBackButton';
import { handleNextPlanStep } from '../../../../redux/slices/carePlanSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Modal from '../../../Modal/Modal';
import { useBillingContext } from '../amount/billingContext';
import { api } from 'api';
import { toast } from 'react-toastify';
import { getBillingTransaction } from 'redux/actions/carePlanAction';
import moment from 'moment';

const Info = ({ title, value }) => (
  <div className={styles.info}>
    <span>{title}</span>
    <p>{value}</p>
  </div>
);

const BillingFilled = () => {
  const [openViewMoreModal, setOpenViewMoreModal] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [billingTransaction, setBillingTransaction] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { apiResponse } = useBillingContext();
  const { MedicareResponseData } = useSelector((state) => state.medicareClaims);
  const [claimSent, setClaimSent] = useState(false);
  const { billingType } = useSelector((state) => state.currentCarePlan) || {};

  const { patient, billingDetails } =
    useSelector((state) => state.currentCarePlan) || {};
  const amount = apiResponse?.userEnteredAmount || 0;

  const customerCardDetails = apiResponse?.data?.customerCardDetails || {};
  const receiptUrl = billingDetails?.receipt;

  const { dateOfTransaction } = apiResponse?.data || {};
  const formatDate = dateOfTransaction
    ? new Date(dateOfTransaction).toLocaleString()
    : '';

  const lastFourDigits = customerCardDetails?.lastFourDigits;

  const location = useLocation();
  const { itemNumber, scheduleFee } =
    useSelector((state) => state.billings.selectedItem) || '';

  const active = useSelector((state) => state.carePlan.activePlanStep) || 0;

  const {
    careplan,
    claimId,
    createdAt,
    doctor,
    items,
    locationId,
    medicareClaimId,
    message,
    status,
    transactionId,
    user,
  } = MedicareResponseData?.data || {};

  const { firstName, lastName, medicareNum, sex } = patient;
  const formattedDateOfBirth = patient?.dateOfBirth
    ? new Date(patient.dateOfBirth).toISOString().split('T')[0]
    : '';

  const formattedDateTime = new Date(createdAt).toLocaleString();

  useEffect(() => {
    if (active) {
      dispatch(handleNextPlanStep({ step: 3 }));
    }
    if (billingDetails?.formattedBillingType === 'Medicare') {
      getBillingTransaction(billingDetails?.stripeChargeId)
        .then((res) => {
          setBillingTransaction(res?.data?.data?.billingDetails);
        })
        .catch((err) => {
          toast.error('Error fetching billing transaction');
        });
    }
  }, [active]);

  const rightContent = {
    timePaid: 'Date and Time Paid',
    paymentMethod: `Card - ****${billingTransaction?.cardDetails?.lastFour}`,
  };

  const handleDownload = async () => {
    try {
      const response = await api.get(`/pdf/?claimId=${claimId}`);
      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.status === true) {
          window.open(responseData.data);
        } else {
          console.error('Download failed. Status:', responseData.status);
        }
      } else {
        console.error('Download failed. Status:', response.status);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  const handleSubmit = async () => {
    if (claimSent) {
      toast.warning('Claim has already been sent.');
      return;
    }

    try {
      const data = {
        locationId: locationId,
        claimId: claimId.toString(),
      };

      const response = await api.post(`/claims/send-claim`, data);

      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.status === true) {
        } else {
          toast.error(responseData?.data?.message);
        }
      } else {
        toast.error('Claim submission failed. Status');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        toast.error(`${errorMessage}`);
      } else {
        toast.error(`Error during claim submission: ${error.message}`);
      }
    }
  };

  const handleReceiptButtonClick = () => {
    if (receiptUrl) {
      window.open(receiptUrl, '_blank');
    } else {
      console.error('Receipt URL is not available');
    }
  };

  const isBulkBillOrMedicareNoCard =
    billingDetails.formattedBillingType === 'BulkBill' ||
    billingDetails.formattedBillingType === 'MedicareNoCard';

  return (
    <div className={styles.root}>
      <section className={styles.topName}>
        <div className={styles.image}>
          <img src={avatar} alt="" />
          <div>
            <h1>{patient?.firstName + ' ' + patient?.lastName}</h1>
            <span>Care Plan</span>
          </div>
        </div>
        <GoBackButton to={-1} />
      </section>
      <section className={styles.container}>
        <div className={styles.header}>
          <h1>Billing</h1>
          <span>Payment for mental health treatment</span>
        </div>
        <div className={styles.contents}>
          {isBulkBillOrMedicareNoCard ? (
            <div className={styles.bulkBill}>
              <div className={styles.bulkBillHeader}>
                <h1>Medicare Billing</h1>
                <p>{status}</p>
              </div>
              <div className={styles.information}>
                <Info title="Medicare Claim ID" value={medicareClaimId} />
                <Info title="Created At" value={formattedDateTime} />

                <Info title="Transaction ID" value={transactionId} />
                <Info title="Type" value={billingDetails.type} />
              </div>
              <div
                onClick={() => setOpenViewMoreModal(true)}
                role="button"
                className={styles.viewMore}
              >
                View More
              </div>
            </div>
          ) : (
            <>
              <div className={styles.left}>
                <div className={styles.leftHeader}>
                  <h1>Medicare Billing</h1>
                  <p>{status}</p>
                </div>
                <div className={styles.information}>
                  <Info title="Medicare Claim ID" value={medicareClaimId} />
                  <Info title="Created At" value={formattedDateTime} />

                  <Info title="Transaction ID" value={transactionId} />
                  <Info title="Type" value={billingDetails.type} />
                </div>
                <div
                  onClick={() => setOpenViewMoreModal(true)}
                  role="button"
                  className={styles.viewMore}
                >
                  View More
                </div>
              </div>

              <div className={styles.right}>
                <div className={styles.rightHeader}>
                  <h1>Patient Billing</h1>
                  <p>Billed from card</p>
                </div>
                <div className={styles.information}>
                  <Info
                    title="Date and Time Paid"
                    value={moment(
                      billingTransaction?.dateOfStripeTransaction
                    ).format('MMMM Do YYYY, h:mm a')}
                  />
                  <Info
                    title="Payment Method"
                    value={rightContent.paymentMethod}
                  />
                </div>
                <div className={styles.summary}>
                  <p>Payment Summary</p>
                  <div>
                    <h1> AUD {amount}</h1>
                    <span>Medicare Claim ID</span>
                    <h1> AUD {billingDetails?.amount}</h1>
                  </div>
                </div>
                <div
                  onClick={handleReceiptButtonClick}
                  role="button"
                  className={styles.viewMore}
                >
                  Receipt
                </div>
              </div>
            </>
          )}
        </div>
        <div>
          {isBulkBillOrMedicareNoCard ? (
            <>
              <button
                onClick={() => navigate('/create-care-plan/patient-note')}
                className={styles.bulkBillButton}
              >
                Continue to Patient Note
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => navigate('/create-care-plan/patient-note')}
                className={styles.done}
              >
                Continue to Patient Note
              </button>
            </>
          )}
        </div>
      </section>
      <Modal
        isOpen={openViewMoreModal}
        onClose={() => setOpenViewMoreModal(false)}
        children={
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h1>Billing details</h1>
              <span>Payment for mental health treatment</span>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.modalLeft}>
                <Info title="Patient" value={`${firstName} ${lastName}`} />
                <Info title="Medicare Number" value={medicareNum} />
                <div className={styles.middle}>
                  <Info title="Date of Birth" value={formattedDateOfBirth} />
                  <Info title="Gender" value={sex} />
                </div>
                <Info title="Charge Amount" value={`AUD ${scheduleFee}`} />
                <Info title="Date of Billing" value={formattedDateTime} />
              </div>
              <div className={styles.modalRight}>
                <div className={styles.rightHeader}>
                  <h1>Billing Status</h1>
                  <p>{status}</p>
                </div>
                <div className={styles.rightItems}>
                  <Info title="Item Number" value={itemNumber} />
                  <Info title="Charge Amount" value={`AUD ${scheduleFee}`} />
                  <Info title="Date" value={formattedDateTime} />
                  <Info title="Medicare Claim ID" value={medicareClaimId} />
                </div>
                <div className={styles.messageNote}>
                  <span>Message / Note</span>
                  <div>{message}</div>
                </div>
                {status === 'MEDICARE_REJECTED' ? (
                  <div className={styles.modalButtons}>
                    <button
                      onClick={() => {
                        setOpenViewMoreModal(false);
                        navigate('/create-care-plan/medicare-claims');
                      }}
                    >
                      Resubmit Claim
                    </button>
                  </div>
                ) : status === 'MEDICARE_PENDABLE' ? (
                  <div className={styles.modalButtons}>
                    <button onClick={handleSubmit}>Submit to Operator</button>
                    <button
                      onClick={() => {
                        setOpenViewMoreModal(false);
                        navigate('/create-care-plan/medicare-claims');
                      }}
                    >
                      Resubmit Claim
                    </button>
                  </div>
                ) : null}

                {billingDetails.formattedBillingType === 'BulkBill' ? (
                  <button onClick={handleDownload} className={styles.done}>
                    Download Db4 Form
                  </button>
                ) : billingDetails?.formattedBillingType === 'Medicare' &&
                  status !== 'MEDICARE_REJECTED' ? (
                  <button onClick={handleDownload} className={styles.done}>
                    Lodgement Form
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        }
      />
      <Modal
        isOpen={openClaimModal}
        onClose={() => setOpenClaimModal(false)}
        children={
          <div className={styles.claimModal}>
            <img src={checkIcon} alt="" />
            <h1>Claim Resubmitted</h1>
            <p>Your bill has been sent to operator successfully.</p>
            <button
              className={styles.btn_done}
              onClick={() => navigate('/create-care-plan/patient-note')}
            >
              Done
            </button>
          </div>
        }
      />
    </div>
  );
};

export default BillingFilled;
