import React, { useEffect, useState } from 'react';
import styles from './createCareBilling.module.scss';
import avatar from '../../../assets/logo/userAvatar.svg';
import GoBackButton from '../../GoBackButton/GoBackButton';
import { handleNextPlanStep } from '../../../redux/slices/carePlanSlice';
import { useDispatch, useSelector } from 'react-redux';
import BillingMethodCard from './card/BillingMethodCard';
import { useNavigate } from 'react-router-dom';
import { api } from 'api';
import { setBillingOption } from 'redux/slices/billingSlices';
import CustomRadio from 'components/CustomRadioButton/CustomRadioButton';
import {
  setCurrentCarePlan,
  updateBillingType,
} from 'redux/slices/currentCarePlan';
import { toast } from 'react-toastify';
import Button from 'components/Button';

const billingTypeOptions = [
  {
    title: 'Medicare',
    value: 'Medicare',
    description: 'Privately bill the patient and process their medicare claim',
  },
  {
    title: 'Bulk Bill',
    value: 'BulkBill',
    description:
      'Accept Medicare reimbursement as full payment for service rendered',
  },
  {
    title: 'Medicare (No Card)',
    value: 'MedicareNoCard',
    description:
      'Patient has already paid but you need to process their medicare claim',
  },
  {
    title: 'No Card/Claim',
    value: 'NoCardClaim',
    description: 'No paid service rendered. Proceed to clinical notes',
  },
];

const CreateCareBilling = () => {
  const { _id, patient, hasStripePaymentBeenMade, billingDetails } =
    useSelector((state) => state.currentCarePlan) || {};
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(
    billingDetails?.formattedBillingType
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const careplanId = _id;

  useEffect(() => {
    dispatch(handleNextPlanStep({ step: 3 }));
  }, []);

  const handleContinue = async () => {
    const billingRouteMap = {
      Medicare: '/create-care-plan/amount',
      BulkBill: '/create-care-plan/medicare-billing',
      MedicareNoCard: '/create-care-plan/medicare-billing',
      NoCardClaim: '/create-care-plan/patient-note',
    };

    setLoading(true);
    const res = await api.patch(
      `/careplan/update-billing-type?careplanId=${careplanId}&billingType=${selectedCard}`
    );
    dispatch(setCurrentCarePlan(res.data.data));
    setLoading(false);
    navigate(billingRouteMap[selectedCard]);
  };

  const handleBack = () => {
    navigate('/create-care-plan/schedule');
  };
  const shouldDisableBillingType =
    billingDetails &&
    billingDetails.formattedBillingType === 'Medicare' &&
    !!billingDetails.amount; // Ensure amount is truthy

  console.log({
    billingDetails,
    shouldDisableBillingType,
  });

  return (
    <div className={styles.root}>
      <section className={styles.topName}>
        <div className={styles.image}>
          <img src={avatar} alt="" />
          <div>
            <h3>{patient?.firstName + ' ' + patient?.lastName}</h3>
            <span>Care Plan</span>
          </div>
        </div>
        <GoBackButton onClick={handleBack} to={-1} />
      </section>
      <section className={styles.container}>
        <div className={styles.title}>
          <h1>Billing</h1>
          <span>Payment for mental health treatment</span>
        </div>
        <div className={styles.content}>
          <div className={styles.contentText}>
            <h1>Choose Billing Method</h1>
            <p>Select the preferred billing option for this patient.</p>
          </div>
          <div className={styles.contentOptions}>
            {billingTypeOptions.map((option, index) => {
              return (
                <BillingMethodCard
                  disabled={shouldDisableBillingType}
                  selectCard={selectedCard}
                  setSelectCard={() => setSelectedCard(option.value)}
                  value={option.value}
                  title={option.title}
                  description={option.description}
                />
              );
            })}
          </div>
          <div className={styles.done}>
            <Button
              onClick={handleContinue}
              disabled={!selectedCard}
              isLoading={loading}
              label={'Continue'}
              type={'button'}
              style={{ cursor: !selectedCard ? 'not-allowed' : 'pointer' }}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateCareBilling;
