import React from 'react';
import { Link } from 'react-router-dom';
import carePlan from '../../assets/logo/cp.svg';
import './terms.module.scss';

const TermSidebar = () => {
  return (
    <>
      <div className="sidenav">
        <div className="sidebar-header">
          <img src={carePlan} alt="careplan logo" className="sidebar-logo" />
          <h3>Care Plan 365 Platform Terms of Service</h3>
        </div>
        <ul>
          <li>
            <a href="#intro">Introduction</a>
          </li>
          <li>
            <a href="#who_this_applies_to">Who this applies to</a>
          </li>
          <li>
            <a href="#about_these_terms">About these terms</a>
          </li>
          <li>
            <a href="#purpose_of_platform">Purpose of this platform</a>
          </li>
          <li>
            <a href="#overview_of_services">Overview of Services</a>
          </li>
          <li>
            <a href="#using_the_platform_and_services">
              Using the Platform and Services
            </a>
          </li>

          <li>
            <a href="#registration">Registration</a>
          </li>
          <li>
            <a href="#privacy_and_consent">Privacy and Consent</a>
          </li>
          <li>
            <a href="#how_much_we_charge">
              How much do we charge for the Services?
            </a>
          </li>
          <li>
            <a href="#payment_process">Payment Process</a>
          </li>
          <li>
            <a href="#what_are_your_obligations">What are your obligations</a>
          </li>
          <li>
            <a href="#termination">
              Termination of access to platform and/or Services
            </a>
          </li>
          <li>
            <a href="#warranties">Warranties</a>
          </li>
          <li>
            <a href="#liability">Liability</a>
          </li>
          <li>
            <a href="#indemnity">Indemnity</a>
          </li>
          <li>
            <a href="#intellectual_property">Intellectual Property</a>
          </li>
          <li>
            <a href="#unavoidable_events">Unavoidable Events</a>
          </li>
          <li>
            <a href="#dispute_resolution">Dispute Resolution</a>
          </li>
          <li>
            <a href="#linking_to_platform">Linking to the Platform</a>
          </li>
          <li>
            <a href="#general_section">General</a>
          </li>
          <li>
            <a href="#definition_section">Definitions</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default TermSidebar;
