import React from 'react';
import './OnboardingLayout.scss';
import carePlan from '../assets/logo/cp.svg';
import TermsAndConditions from 'components/TermsAndConditions';

const OnboardingLayout = ({ children }) => {
  return (
    <div className="layout">
      <div className="header">
        <img src={carePlan} alt="careplan logo" />
      </div>
      <main className="content">{children}</main>
      <div className="terms_footer">
        <TermsAndConditions />
      </div>
    </div>
  );
};

export default OnboardingLayout;
