import React from 'react';
import styles from './detailsInformation.module.scss';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';

const Info = ({ title, value }) => (
  <div className={styles.info}>
    <span>{title}</span>
    <p>{value}</p>
  </div>
);

const DetailsInformation = ({
  userInfo,
  doctorInfo,
  lastCareplanDate,
  careplanId,
}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.header}>
          <h1>General Information</h1>
        </div>

        <div>
          <h1 className={styles.subHeader}>Address</h1>
          <div className={styles.information}>
            <Info title="Street" value={userInfo?.street} />
          </div>

          <div className={styles.information}>
            <div className={styles.addressInfo}>
              <Info title="Country" value={userInfo?.country} />
              <Info title="Suburb" value={userInfo?.city} />
              <Info title="Postal Code" value={userInfo?.postalCode} />
            </div>
          </div>
        </div>

        <h1 className={styles.subHeader}>Other Information</h1>
        <div className={styles.information}>
          <Info
            title="Date of Birth"
            value={`${moment(userInfo?.dateOfBirth).format(
              'LL'
            )} (${moment().diff(userInfo?.dateOfBirth, 'years')} Years)`}
          />

          <Info title="Gender" value={userInfo?.sex} />

          <Info title="Medicare Id" value={userInfo?.medicareNum} />
          <Info title="IRN" value={userInfo?.medicareReferralNumber} />

          <Info
            title="Most Recent K10 Score"
            value={userInfo?.recentKTenScore}
          />
          <Info title="Phone" value={userInfo?.phoneNumber} />
          <Info title="Email address" value={userInfo?.email} />
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.header}>
          <h1>Patient Care Plan</h1>
        </div>
        {careplanId && (
          <div className={styles.creator}>
            <span className={styles.date}>
              Created on {moment(lastCareplanDate).format('LL')}
            </span>
            <div className={styles.name}>
              <p>
                By Dr. {doctorInfo?.practitioner?.firstName}{' '}
                {doctorInfo?.practitioner?.lastName}
              </p>
              <span>{doctorInfo?.practitioner?.type}</span>
            </div>
            <div className={styles.viewCarePlanBtn}>
              <Button
                label={'View Care Plan'}
                type={'button'}
                onClick={() =>
                  navigate(`/dashboard/careplan-summary/${careplanId}`)
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsInformation;
