import React from 'react';
import './SignInLayout.scss';
import carePlan from '../assets/logo/cp.svg';
import TermsAndConditions from 'components/TermsAndConditions';

const SignInLayout = ({ children }) => {
  return (
    <div className="sign-in-layout">
      <main className="content">
        <div className="header">
          <img src={carePlan} alt="careplan logo" />
        </div>
        {children}
      </main>
      <div className="terms_footer">
        <TermsAndConditions />
      </div>
    </div>
  );
};

export default SignInLayout;
