import SignUpForm from '../components/SignUpForm';
import OnboardingLayout from '../layouts/OnboardingLayout';
import './SignUpPage.scss';

const SignUpPage = () => {
  return (
    <div>
      <OnboardingLayout>
        <SignUpForm />
      </OnboardingLayout>
    </div>
  );
};

export default SignUpPage;
