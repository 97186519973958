import React, { useEffect } from 'react';
import styles from './billingSummary.module.scss';
import GoBackButton from '../../GoBackButton/GoBackButton';
import avatar from '../../../assets/logo/userAvatar.svg';
import userIcon from '../../../assets/logo/user.png';
import { handleNextPlanStep } from '../../../redux/slices/carePlanSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCarePlanById } from 'redux/actions/patientAction';
import { fetcher } from 'api';
import useSWR from 'swr';
import moment from 'moment';
import Button from 'components/Button';
import { Spin } from 'antd';
import { sendToPatient } from 'redux/actions/carePlanAction';
import { toast } from 'react-toastify';

const BillingSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const params = useParams();

  const { data, error, isLoading, isValidating } =
    useSWR([`/careplan/${params?.id}`], fetcher) || {};

  const sendToPatientHandler = async () => {
    try {
      setLoading(true);
      await sendToPatient(data._id, data.patient._id);
      toast.success('Care plan sent to patient successfully');
      navigate('/dashboard');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleBack = () => {
    navigate('/dashboard');
  };
  console.log('data:', data);

  return (
    <div className={styles.root}>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.95)',
            zIndex: 1000,
          }}
        >
          <Spin tip="Loading..." className={styles['ant-spin-dot-item']} />
        </div>
      )}

      <div className={styles.header}>
        <GoBackButton onClick={handleBack} to={-1} />
        <div className={styles.topBtnContainer}>
          <div className={styles.downloadBtn}>
            <Button type="button" label={'Save as PDF'} />
          </div>
          <div className={styles.sendToPatientBtn}>
            <Button
              label={'Send to Patient'}
              type={'button'}
              isLoading={loading}
              onClick={sendToPatientHandler}
            />
          </div>
        </div>
      </div>
      <div className={styles.subHeading}>
        <h1>Care Plan Summary - {moment(data?.createdAt).format('LL')}</h1>
        <div className={styles.headingImage}>
          <img src={data?.practitioner?.imageUrl || avatar} alt="" />
          <div>
            <h2>
              {data?.practitioner?.firstName +
                ' ' +
                data?.practitioner?.lastName}
            </h2>
            <span>{data?.practitioner?.type}</span>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.leftHeading}>
            <img src={avatar} alt="" />
            <div className={styles.careNumber}>
              <h1>
                {data?.patient?.firstName + ' ' + data?.patient?.lastName}
              </h1>
              <span>Medicare Number: {data?.patient?.medicareNum}</span>
              <div>
                <p>K10 Score</p>
                <span>{data?.assessment?.score}</span>
              </div>
            </div>
          </div>
          <div className={styles.stressors}>
            <h1>Stressors</h1>
            <div className={styles.stressContents}>
              {data?.assessment?.stressors &&
                Object.keys(data?.assessment?.stressors)
                  .filter((key) => data?.assessment?.stressors[key])
                  .map((data, index) => (
                    <div className={styles.stress}>
                      <span>{index + 1}</span>
                      <p>{data}</p>
                    </div>
                  ))}
            </div>
          </div>
          <div className={styles.assessment}>
            <h1>Assessment</h1>
            <div className={styles.assess}>
              <div className={styles.singleAssessment}>
                <p>Risk to Self</p>
                <span>{data?.riskToSelf}</span>
              </div>
              <div className={styles.singleAssessment}>
                <p>Risk to Others</p>
                <span>{data?.riskToOthers}</span>
              </div>
            </div>
          </div>
          <div className={styles.shortTermGoal}>
            <h1>Short Term Goal</h1>
            <p>{data?.assessment?.shortTermGoal?.message}</p>
          </div>
          <div className={styles.longTermGoals}>
            <h1>Long Term Goal</h1>
            <p>{data?.assessment?.longTermGoal}</p>
          </div>
          <div className={styles.diagnosis}>
            <h1>Diagnosis</h1>
            <div>
              {data?.diagnosis?.map((data, index) => (
                <p key={index}>{data}</p>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.medication}>
            <h1>Medication</h1>

            {data?.medication?.map((data, index) => (
              <div className={styles.singleMedication}>
                <div className={styles.medicationTitle}>
                  <p>{index + 1}</p>
                  <div>
                    <h1>{data?.drugName}</h1>
                    {/* <span>BP 50 mg</span> */}
                  </div>
                </div>
                <div>
                  <h2>Dose</h2>
                  <h5>{data?.dosage}</h5>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.therapy}>
            <h1>Therapy</h1>
            <div className={styles.therapyContent}>
              <div>
                <span>Type of therapy</span>
                <p>{data?.therapy.therapyType}</p>
              </div>
              <div>
                <span>Others</span>
                <p>{data?.therapy?.otherInterventions}</p>
              </div>
              <div>
                <span>Preliminary Safety Plan Discussion</span>
                <p>{data?.therapy?.hasSafetyPlanning ? 'Yes' : 'No'}</p>
              </div>
            </div>
          </div>
          <div className={styles.schedules}>
            <h1>Schedule</h1>
            {data?.homework.map((data, index) => (
              <div key={data?._id} className={styles.schedule}>
                <div>
                  <span>Task {index + 1}</span>
                  <p>{data?.task}</p>
                </div>
                <div>
                  <span>Duration</span>
                  <p>{data?.duration}</p>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.appointment}>
            <h1>Appointment</h1>
            <div>
              <h2>
                {moment(data?.appointment?.appointmentDate)
                  .utc()
                  .format('dddd, MMMM Do YYYY, h:mm a')}
              </h2>
              <a href="">{data?.appointment?.meetingLink}</a>
            </div>
          </div>
          <div className={styles.requests}>
            <h1>Requests /Orders</h1>
            {data?.requests?.map((data, index) => (
              <div>
                <p key={index}>{data}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingSummary;
